import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";

import shortid from "shortid";

import "twin.macro";

import { useSeo } from "@queries";

import { Layout } from "@components/layout";
import { Heading, Image, ErrorMsg } from "@components/shared";

const GalleryPage = ({ data }) => {
  const { description_gallery } = useSeo();

  const { images } = data.sanityGalleryPage;

  return (
    <Layout title="Galeria" description={description_gallery}>
      <Heading as="h1" tw="text-center mb-12">
        Galeria
      </Heading>
      <div tw="flex flex-wrap justify-between">
        {images && images.length ? (
          images.map((image) => (
            <a
              key={shortid.generate()}
              href={image.asset.fluid.src}
              target="_blank"
              rel="noreferrer noopener"
              tw="block w-full lg:w-col mb-8 xl:mb-12 hover:cursor-zoom-in"
            >
              <Image fluid={image.asset.fluid} alt={image.alt} />
            </a>
          ))
        ) : (
          <ErrorMsg msg="Brak zdjęć" />
        )}
      </div>
    </Layout>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.shape({
    sanityGalleryPage: PropTypes.shape({
      images: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query GalleryPageQuery {
    sanityGalleryPage {
      images {
        alt
        asset {
          fluid(maxWidth: 1280) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
  }
`;

export default GalleryPage;
